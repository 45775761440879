<template>
  <div class="main">
    <div class="d-flex justify-content-center" v-if="loadingSpinner">
      <b-spinner variant="success" label="Spinning" style="width: 3rem; height: 3rem"></b-spinner>
    </div>

    <b-row v-if="!loadingSpinner">
      <b-col cols="12" class="mt-1">
        <div class="d-flex justify-content-end">
          <b-button variant="primary" v-b-modal.modal-addcategory class="my-1" to="/jobcard/add">
            Process Orders
          </b-button>
        </div>
      </b-col>

      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1"> </b-col>
      <b-col md="6" class="my-1">
        <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
          class="mb-0">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="jobcardList"
          :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
          :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
          <template v-slot:cell(name)="data">
            <b-link variant="primary" :to="getId(data.item.id)">
              {{ data.item.name }}
            </b-link>
          </template>
          <template #cell(startDate)="data">
            {{ dayjs(data.value).format("DD/MM/YYYY hh:mm:ss") }}
          </template>

          <template #cell(endDate)="data">
            {{ dayjs(data.value).format("DD/MM/YYYY hh:mm:ss") }}
          </template>
          <template #cell(deliveryDate)="data">
            {{ dayjs(data.value).format("DD/MM/YYYY hh:mm:ss") }}
          </template>
          <template v-slot:cell(status)="data">
            <b-badge v-if="data.item.status === 0" variant="info">
              <span>Not Started</span>
            </b-badge>
            <b-badge v-else-if="data.item.status === 1" variant="secondary">
              <span>Started</span>
            </b-badge>
            <b-badge v-else-if="data.item.status === 2" variant="primary">
              <span>Completed</span>
            </b-badge>
            <b-badge v-else-if="data.item.status === 3" variant="danger">
              <span>Cancelled</span>
            </b-badge>
            <b-badge v-else-if="data.item.status === 4" variant="warning">
              <span>Blocked</span>
            </b-badge>
          </template>
          <template v-slot:cell(complete)="data">
            <b-form-checkbox v-if="data.item.status == 1 || data.item.status == 3 || data.item.status == 4"
              :checked="isChecked(data.item.id)" :disabled="isDisabled(data.item.status)"
              @change="checkboxChanged(data.item.id, $event)">
            </b-form-checkbox>
          </template>
        </b-table>

        <div class="d-flex justify-content-end">
  <b-button 
    variant="primary" 
    class="my-1" 
    @click="completeJobCards" 
    :disabled="apiLoading"
  >
    Complete Job Cards
  </b-button>
</div>

        <b-alert variant="danger" show v-if="error" class="d-flex justify-content-center">
          <h4 class="alert-heading">{{ errorMessage }}</h4>
        </b-alert>
        <b-alert variant="info" show v-if="jobcardList.length === 0 && !error" class="d-flex justify-content-center">
          <h4 class="alert-heading">No jobcards Yet</h4>
        </b-alert>

        <div class="d-flex justify-content-center mb-1">
          <b-spinner variant="success" label="Spinning" block v-if="loading"
            style="width: 3rem; height: 3rem"></b-spinner>
        </div>
      </b-col>

      <b-col cols="12">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
          class="my-0" />
          <b-form-group>
      Showing {{ perPage * (currentPage - 1) + 1}} 
      to {{ Math.min(perPage * currentPage, totalRows) }} 
      of {{ totalRows }} entries
        </b-form-group>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BLink,
  BAlert,
  BFormCheckbox,
} from "bootstrap-vue";

import { mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";

export default {
  components: {
    BTable,
    BAlert,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BLink,
    BFormCheckbox,
  },
  data() {
    return {
      dayjs,
      loadingSpinner: true,
      perPage: 5,
      errorMessage: "",
      categoryName: "",
      apiLoading: false,
      error: false,
      pageOptions: [3, 5, 10],
      totalRows: 0,
      currentPage: 0,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      selectedItems: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "itemName",
          label: "Product",
        },
        {
          key: "description",
          label: "Description",
        },

        {
          key: "quantity",
          label: "Quantity",
        },
        {
          key: "startDate",
          label: "Start Date",
        },
        {
          key: "deliveryDate",
          label: "Delivery Date",
        },
        {
          key: "endDate",
          label: "End Date",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "complete",
          label: "Complete",
        }
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    ...mapGetters("jobcardModule", {
      jobcardList: "jobcards",
      loading: "loading",
    }),

    ...mapGetters("productModule", {
      productList: "product",
      loading: "loading",
    }),
  },
  methods: {
    showSuccess() {
      this.$swal({
        title: "Job Cards Completed!",
        text: "You have successfully completed the selected Job Cards!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      this.getJobcardListAction();
    },

    showError(data) {
      this.$swal({
        title: "Error!",
        text: data.response.data.message,
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      this.getJobcardListAction();
    },
    isChecked(itemId) {
      return this.selectedItems.some(item => item.id === itemId);
    },
    isDisabled(status) {
      return [0, 2, 3, 4].includes(status);
    },
    checkboxChanged(itemId, isChecked) {
      const itemStatus = 2;
      const existingItemIndex = this.selectedItems.findIndex(item => item.id === itemId);

      if (isChecked) {
        if (existingItemIndex === -1) {
          this.selectedItems.push({ id: itemId, status: itemStatus });
        } else {
          this.selectedItems[existingItemIndex].status = itemStatus;
        }
      } else {
        if (existingItemIndex !== -1) {
          this.selectedItems.splice(existingItemIndex, 1);
        }
      }
      console.log(this.selectedItems);
    },
    completeJobCards() {
  this.apiLoading = true;

  // Showing swal loader
  this.$swal({
    title: 'Loading...',
    allowEscapeKey: false,
    allowOutsideClick: false,
    onOpen: () => {
      this.$swal.showLoading();
    }
  });

  this.updateMultipleJobCardAction(this.selectedItems)
    .then(() => {
      this.$swal.close();
      this.showSuccess();
    })
    .catch(error => {
      this.$swal.close();
      this.showError(error);
    })
    .finally(() => {
      this.apiLoading = false;
    });
},

    getId(data) {
      return "/jobcard/view/" + data;
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getProductName(id) {
      for (let i = 0; i < this.productList.length; i++) {
        if (this.productList[i].id == id) {
          return this.productList[i].name;
        }
      }
    },

    ...mapActions("jobcardModule", ["getJobcardListAction", "updateMultipleJobCardAction"]),
    ...mapActions("productModule", ["getProductListAction"]),
  },
  async mounted() {
    setTimeout(() => {
      this.loadingSpinner = false;
    }, 5000);
    await this.getJobcardListAction()
      .then(() => {
        this.totalRows = this.jobcardList.length;
      })
      .catch((response) => {
        this.error = true;
        this.errorMessage = response.response.data.message;

      });
  },
};
</script>
